<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-button
          variant="primary"
          class="d-flex align-items-center justify-content-center"
          @click="downloadFile()"
        >
          <feather-icon icon="UserPlusIcon" size="16" class="mr-50" />
          <span class="text-nowrap">{{ $t('labels.export') }}</span>
        </b-button>
        <b-button v-b-modal.modal-hierachy v-if="width<768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="9">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{
          $t("tabs.reportglobal")
          }}</b-card-title>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFrom"
                  class="form-control"
                  :config="{
                  altInput: true,
                  altFormat: 'd-m-Y - H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d:H:i',
                  time_24hr: true,
                }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateTo"
                  class="form-control"
                  :config="{
                  altInput: true,
                  altFormat: 'd-m-Y - H:i',
                  enableTime: true,
                  dateFormat: 'Y-m-d:H:i',
                  time_24hr: true,
                }"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
                <b-form-group
                  label="Currency"
                  label-for="currency"
                >
                  <template #label>{{$t('labels.currency')}}</template>
                  <v-select
                    v-model="currency"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currenciesOptions"
                    :reduce="(option) => option.abbreviation"
                    @input="currencyChanged"
                    :clearable="false"
                    label="name"
                  />
                </b-form-group>
                </b-col> -->
          </b-row>
          <b-row>
            <b-col md="12" class="d-flex justify-content-between w-100">
              <!-- <b-button 
                variant="primary"
                @click="getTotalLazy" >{{$t('buttons.search')}}</b-button> -->

              <div v-if="dataTable.length > 0">
                <b-card-text class="mb-0 mr-1"> Details </b-card-text>
                <b-form-checkbox
                  v-model="detailsDataChecked"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </div>

              <!-- <b-button 
                type="submit"
                variant="primary"
                @click="getDetails" >Details</b-button> -->
            </b-col>
          </b-row>
          <br />
          <b-overlay :show="loadindData">
            <div v-if="dataTable.length > 0">
              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>{{ $t("category") }}</b-th>
                    <b-th>{{ $t("bet") }}</b-th>
                    <b-th>{{ $t("betted_amount") }}</b-th>
                    <b-th>{{ $t("win") }}</b-th>
                    <b-th>{{ $t("net_win") }}</b-th>
                    <b-th>{{ $t("commission") }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in dataTable"
                    :key="indexTr"
                  >
                    <b-td class="text-noWrap">{{ tr.Category }}</b-td>
                    <b-td class="text-noWrap">{{ tr.Bets }}</b-td>
                    <b-td
                      class="text-noWrap"
                      >{{ tr.Betted | currency({ symbol: '' }) }}</b-td
                    >
                    <b-td
                      class="text-noWrap"
                      >{{ tr.Won | currency({ symbol: '' }) }}</b-td
                    >
                    <b-td
                      class="text-noWrap"
                      >{{ tr.NetWin | currency({ symbol: '' }) }}</b-td
                    >
                    <b-td
                      class="text-noWrap"
                      >{{ tr.Commissions | currency({ symbol: '' }) }}</b-td
                    >
                  </b-tr>
                </b-tbody>
              </b-table-simple>

              <b-table-simple class="mt-2" hover small caption-top responsive>
                <b-thead head-variant="light">
                  <b-th colspan="2">Venta Fichas</b-th>
                  <b-th
                    >{{ dataTotal.totals.recharges | currency({ symbol: '' }) }}
                  </b-th>
                  <b-th
                    >{{ dataTotal.totals.retirement | currency({ symbol: '' }) }}
                  </b-th>
                  <b-th
                    >{{ dataTotal.totals.diff | currency({ symbol: '' }) }}
                  </b-th>
                </b-thead>
                <b-tr>
                  <b-th colspan="4">Tabs on Panels:</b-th>
                  <b-td
                    >{{ dataTotal.totals.agents | currency({ symbol: '' }) }}
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th colspan="4">Total Players:</b-th>
                  <b-td>
                    <p v-if="totalPlayers !== null">
                      {{ totalPlayers | currency({ symbol: '' }) }}
                    </p>
                    <b-overlay
                      v-else
                      :show="loadindDataBalancePlayer"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        variant="primary"
                        @click="fetchPlayerBalances"
                        size="sm"
                      >
                        Search
                      </b-button>
                    </b-overlay>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers
          @selectUser="selectUserHierarchy"
          ref="HerarchiComponent"
        />
      </b-modal>
      <b-col class="p-0" md="3" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="selectUserHierarchy" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import userStoreModule from "../../apps/user/userStoreModule";
import clientStoreModule from "../../pages/clients/clientStoreModule";
import whitelabelStoreModule from "../../pages/whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
    HierarchyUsers,
  },
  data() {
    return {
      totalPlayers: null,
      dateFrom: moment().format("YYYY-MM-DD:00:00:00"),
      dateTo: moment().format("YYYY-MM-DD:23:59:59"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataGamesFullDetails: [],
      dataTable: [{}], // dataTable : []
      rangeDate: "",
      idUserSelect: "",
      detailsDataChecked: false,
      loadindData: false,
      typeUser: '',
      loadindDataBalancePlayer: false,
      dataTotal: {
        totals: {
          recharges: 0,
          retirement: 0,
          diff: 0,
          paneles: 0,
        },
      },
      timeOut: null,
      width: 0,
    };
  },
  watch: {
    detailsDataChecked(val) {
      if (val) this.dataTable = this.dataGamesFullDetails;
      else this.dataTable = this.dataGames;
    },
    dateFrom() {
      this.getTotalLazy();
    },
    dateTo() {
      this.getTotalLazy();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const CLIENT_APP_STORE_MODULE_NAME = "app-client";
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
      store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);
    if (!store.hasModule(WL_APP_STORE_MODULE_NAME))
      store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
    });
    const currencies = ref([]);
    const clientCurrencies = ref([]);
    // try{
    //   store.dispatch('app-whitelabel/fetchCurrencies')
    //   .then(response => {
    //     currencies.value = response.data
    //     const userLogged = JSON.parse(localStorage.getItem('userData'))
    //     if (userLogged.is_admin){
    //       clientCurrencies.value = currencies.value
    //     }
    //     if (!userLogged.is_admin) {
    //       clientCurrencies.value = [] // the currencies of the logged in user are taken
    //       store.dispatch('app-client/fetchClient', { id: userLogged.clients })
    //         .then(response => {
    //           response.data.data.currencies.forEach(curr => {
    //             const currency = currencies.value.find(element => element.abbreviation == curr)
    //             if (currency) {
    //               var currenciesAux = {
    //                 abbreviation: currency.abbreviation,
    //                 name: currency.name,
    //               }
    //               clientCurrencies.value.push(currenciesAux)
    //               }
    //             })
    //           })
    //         .catch(error => {
    //             console.log("error fetching client: ", error)
    //         })
    //     }
    //   })
    // }catch (error) {
    //   console.error(error);
    // }
    const users = ref([]);
    const treeData = ref([]);
    const previosNode = ref("");
    const previousNodeFlag = ref(false);
    //
    // ** Construction of user hierarchy **
    //
    // try {
    //   store.dispatch('app-user/fetchUsersAll')
    //   .then(response => {
    //      var newArray = response.data.users.filter((item) => item.status == true )
    //       users.value = newArray
    //   })
    //   .then( () => {
    //     // the logged in user is level 0
    //     const userLogged = JSON.parse(localStorage.getItem('userData'))
    //     var treeDataAux = {
    //       id: userLogged._id,
    //       title: userLogged.userName,
    //       expanded: true,
    //       children: []
    //     }
    //     treeData.value.push(treeDataAux)
    //    // the children of the logged in user are searched for level 1
    //     userLogged.children.forEach(child => {
    //       const childData = users.value.find(element => element._id == child.userId)
    //       if (childData) {
    //          var treeDataAux = {
    //           id: childData._id,
    //           title: childData.userName,
    //           expanded: false,
    //           children: []
    //         }
    //         treeData.value[0].children.push(treeDataAux)
    //       }
    //     })
    //     // set up to level 5
    //     // the level 1 children is traversed to search for level 2
    //     for (var i = 0; i < treeData.value[0].children.length; i++) {
    //       const childArray = users.value.find(element => element._id == treeData.value[0].children[i].id)
    //       if (childArray) {
    //         // if (childArray.children[0].userId != 'x') {
    //           childArray.children.forEach(child => {
    //             const user = users.value.find(element => element._id == child.userId)
    //             if (user) {
    //               var treeDataAux = {
    //                 id: child.userId,
    //                 title: user.userName,
    //                 expanded: false,
    //                 children: []
    //               }
    //               treeData.value[0].children[i].children.push(treeDataAux)
    //             }
    //           })
    //         // }
    //       }
    //        // the level 2 children is traversed to search for level 3
    //       for (var j = 0; j < treeData.value[0].children[i].children.length; j++) {
    //         const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].id)
    //         if (childArray) {
    //           childArray.children.forEach(child => {
    //             const user = users.value.find(element => element._id == child.userId)
    //             if (user){
    //               var treeDataAux = {
    //                 id: user.userId,
    //                 title: user.userName,
    //                 expanded: false,
    //                 children: []
    //               }
    //               treeData.value[0].children[i].children[j].children.push(treeDataAux)
    //             }
    //           })
    //         }
    //         // the level 3 children is traversed to search for level 4
    //         for (var k = 0; k < treeData.value[0].children[i].children[j].children.length; k++) {
    //           const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].children[k].id)
    //           if (childArray) {
    //             childArray.children.forEach(child => {
    //               const user = users.value.find(element => element._id == child.userId)
    //               if (user){
    //                 var treeDataAux = {
    //                   id: user.userId,
    //                   title: user.userName,
    //                   expanded: false,
    //                   children: []
    //                 }
    //                 treeData.value[0].children[i].children[j].children[k].children.push(treeDataAux)
    //               }
    //             })
    //           }
    //           // the level 4 children is traversed to search for level 5
    //           for (var l = 0; l < treeData.value[0].children[i].children[j].children[k].children.length; l++) {
    //             const childArray = users.value.find(element => element._id == treeData.value[0].children[i].children[j].children[k].children[l].id)
    //             if (childArray) {
    //               childArray.children.forEach(child => {
    //                 const user = users.value.find(element => element._id == child.userId)
    //                 if (user){
    //                   var treeDataAux = {
    //                     id: user.userId,
    //                     title: user.userName,
    //                     expanded: false,
    //                     children: []
    //                   }
    //                   treeData.value[0].children[i].children[j].children[k].children.push(treeDataAux)
    //                 }
    //               })
    //             }
    //           } //for l
    //         }  //for k
    //       }  // for j
    //     } // for i
    //   }) //end then
    // } catch (error) {
    //   console.error(error);
    // }
    //
    // ** End construction of user hierarchy **
    //
    const selection = ref([]);
    const usersForReport = ref([]);
    const dataGames = ref([]);
    const game = ref([]);
    return {
      dataGames,
      game,
      treeData,
      users,
      usersForReport,
      currencies,
      clientCurrencies,
      selection,
      previousNodeFlag,
      previosNode,
    };
  },
  computed: {
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },
  methods: {
    downloadFile() {
      const typeUser = this.typeUser
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const downloadFile = VUE_APP_URL + "/media/exports/excel/" + typeUser + ".xlsx"
      window.open(downloadFile, "_self")
    },
    async fetchPlayerBalances() {
      try {
        this.loadindDataBalancePlayer = true;
        const payload = {
          id: this.idUserSelect,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
        };
        const { data } = await store.dispatch(
          "app-client/fetchPlayerBalances",
          payload
        );
        this.totalPlayers = data.balancePlayers;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadindDataBalancePlayer = false;
      }
    },
    async exportsData() {
      const payload = {
        datein: this.dateFrom,
        dateout: this.dateTo,
        id: this.idUserSelect,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar._id,
        exportExcel: false,
      };
      const { data } = await store.dispatch(
        "app-client/fetchReportGateway",
        payload
      );
    },
    async getTotals() {
      this.game = [];
      this.dataGames = [];
      this.dataGamesFullDetails = [];
      this.validateData();
      try {
        if (this.idUserSelect == "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert!",
              icon: "AlertTriangleIcon",
              text: "Select a user in the tree",
              variant: "warning",
            },
          });
          return false;
        }
        this.loadindData = true;
        this.totalPlayers = null;
        const payload = {
          datein: this.dateFrom,
          dateout: this.dateTo,
          id: this.idUserSelect,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
        };
        const { data } = await store.dispatch(
          "app-client/fetchReportGateway",
          payload
        );
        console.log("--- DATA ENTRANTEEEEEE: ", data)
        this.typeUser = data.typeUser
        this.exportsData();
        this.dataTotal = data;
        this.game = data.data;
        var gameAux = {
          Category: this.game.Name,
          Bets: this.game.Bets,
          Betted: this.game.Betted,
          Won: this.game.Won,
          NetWin: this.game.NetWin,
          Commissions: this.game.Commissions,
          _rowVariant: "success",
        };
        this.dataGames.push(gameAux);
        this.dataGamesFullDetails.push(gameAux);
        const categories = this.game.Categories.length;
        for (var i = 0; i < categories; i++) {
          var gameAux = {
            Category: this.game.Categories[i].Name,
            Bets: this.game.Categories[i].Bets,
            Betted: this.game.Categories[i].Betted,
            Won: this.game.Categories[i].Won,
            NetWin: this.game.Categories[i].NetWin,
            Commissions: this.game.Categories[i].Commission,
            _rowVariant: "info",
          };
          console.log(this.game.Categories[i], "this.game.Categories[i]")
          this.dataGames.push(gameAux);
          this.dataGamesFullDetails.push(gameAux);
          const products = this.game.Categories[i].Products.length;
          for (var j = 0; j < products; j++) {
            var gameAux = {
              Category: this.game.Categories[i].Products[j].Name,
              Bets: this.game.Categories[i].Products[j].Bets,
              Betted: this.game.Categories[i].Products[j].Betted,
              Won: this.game.Categories[i].Products[j].Won,
              NetWin: this.game.Categories[i].Products[j].NetWin,
              Commissions: this.game.Categories[i].Products[j].Commission,
            };
            this.dataGamesFullDetails.push(gameAux);
          }
        }
        if (this.detailsDataChecked) this.dataTable = this.dataGamesFullDetails;
        else this.dataTable = this.dataGames;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadindData = false;
      }
    },
    validateData() {
      if (this.dateTo < this.dateFrom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: "Review dates period",
            variant: "danger",
          },
        });
      }
    },
    selectUserHierarchy({ id }) {
      this.idUserSelect = id;
      this.getTotalLazy();
    },
    getTotalLazy() {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.getTotals();
      }, 500);
    },
  },
  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;


    this.width = window.innerWidth;
    window.addEventListener('resize', () => {
      this.width = window.innerWidth;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}
.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
